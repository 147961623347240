header {
  display: flex;
  flex-direction: column;
}
.page-user {
  position: relative;
  overflow: auto;
}
.forgotModal {
  .header {
    text-align: center;
    font-size: 16pt;
  }
  p {
    font-size: 12pt;
    line-height: 1;
    margin-bottom: 0;
    text-align: center;
  }
  .content {
    padding-top: 0 !important;
    img {
      margin-bottom: 20px;
    }
  }
  .ui.input {
    display: block;
    margin: 10px 25%;
    input {
      width: 250%;
    }
  }
  .ui.button {
    width: 50%;
    margin: 0 25%;
    background-color: #92d2df;
  }
  .description {
    padding-bottom: 20px;
  }
  .ui.medium.image {
    width: 75px;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .boxPadding {
    padding: 25px;
  }
  .checkEmail {
    background-color: white !important;
    border: 1px solid #2d3c46;
    width: 60% !important;
    margin: 0 20% !important;
  }
  .btnClose {
    position: relative;
    top: 18px;
    left: 30px;
    background-color: transparent !important;
    border: none;
    font-size: 10pt;
    font-weight: bold;
    color: #2d3c46;
    &.backToLogin {
      top: 36px;
    }
  }
}
.errorMessage {
  width: 100%;
  background-color: #f7d6dc;
  border-radius: 5px;
  color: red;
  font-weight: 500;
  border: 1px solid;
  padding: 5px 9px;
  margin-bottom: 4px;
  font-family: Roboto;
  &.email {
    width: 50%;
    margin: 0 auto;
  }
}
.login-page {
  .loginBox {
    border-bottom: 1px solid #7edfeb;
    top: 0;
    padding-top: 25vh;
  }
  .signupBox {
    top: 11px;
    .logoMain {
      display: block;
      margin: 0 auto;
      border-bottom: 1px solid #31678c;
      img {
        width: 116px;
        margin-bottom: 12px;
      }
    }
    .title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
      padding-top: 210px;
      padding-bottom: 9px;
    }
    .text {
      font-size: 14px;
      color: #fff;
      text-align: center;
      display: block;
      padding-top: 12px;
    }
    .validateField {
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      padding: 6px;
    }
  }
  .inner {
    width: 300px;
    margin: 0 auto;
    .emailPasswordAccount {
      margin-top: 10px;
      input {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 5px;
        padding: 0 9px;
        color: #000000;
        font-size: 14px;
        line-height: 19px;
        margin: 4px 0;
        font-family: Roboto;
      }
      button {
        width: 100%;
        background-color: #7edfeb;
        border: none;
        height: 40px;
        margin: 4px 0;
        border-radius: 5px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
      }
      .forgotPassword {
        text-align: center;
        margin-top: 10px;
      }
    }
    .border {
      border-bottom: 1px solid #fff;
      margin: 17px 3rem;
    }
  }
  .text {
    color: #fff;
    text-align: center;
    font-size: 13px;
    padding: 6px;
    display: table;
    background-color: transparent;
    &.or {
      margin: -17px auto;
      background-color: #1b394e;
    }
    &.top {
      margin-top: 129px;
    }
  }
  .linkText {
    color: #7edfeb;
    font-size: 14px;
    &.password {
      font-weight: 500;
    }
  }
  .linkButton {
    color: #7edfeb;
    border: none;
    height: auto !important;
    font-size: 14px !important;
    background-color: transparent !important;
  }
  .logoMain {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  .firebaseui-idp-google {
    height: 44px;
    display: block;
    width: 100%;
    padding: 0 5px;
    outline: none;
    border: 0;
    background-color: #fff;
    border-radius: 5px;
    font-size: 16px;
    color: #1b394e;
    line-height: 44px;
    text-decoration: none;
    text-align: center;
    &:hover {
      background-color: #92d2df;
      .firebaseui-idp-text {
        color: #fff;
      }
    }
    .firebaseui-idp-text {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      display: inline-block;
    }
  }
  .mdl-button {
    min-width: 100%;
  }
  .firebaseui-card-content {
    padding: 0;
  }
}

.page-user {
  .verify {
    .contentVerify {
      padding-top: 20vh;
      p {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }
    }
    header {
      img {
        float: none;
      }
    }
  }
  .displayuser {
    padding: 75px 3% 30px;
    background-color: white;
    .head {
      clear: both;
      padding-top: 20px;
      overflow: hidden;
      p {
        font-size: 20px;
        float: left;
        color: #1b394e;
      }
      .upload {
        display: none;
      }
      input {
        border: 1px #e0e0e0 solid;
        border-radius: 5px;
        float: right;
        height: 38px;
        color: #1b394e;
        padding-left: 30px;
        font-size: 14px;
        -webkit-appearance: none;
        outline: none;
        background: #fff url("../images/ic_search.png") no-repeat 10px center;
        background-size: 15px auto;
      }
      .iconJS {
        border-radius: 50%;
        background-color: #1b394e;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        line-height: 50px;
        float: left;
      }
    }
    .noResult {
      border-bottom: 1px #e0e0e0 solid;
      border-left: 1px #e0e0e0 solid;
      border-right: 1px #e0e0e0 solid;
      text-align: center;
      width: 100%;
      font-size: 16px;
      color: #6c7077;
      p {
        text-align: center;
        padding: 15px 0;
        margin: 0;
      }
    }
    .content {
      display: table;
      margin-top: 20px;
      width: 100%;
      border-top: 1px #e0e0e0 solid;
      border-left: 1px #e0e0e0 solid;
      border-right: 1px #e0e0e0 solid;
      .Heading {
        display: table-row;
        text-align: center;
        .Cell {
          display: table-cell;
          border: none;
          border-bottom: 1px #e0e0e0 solid;
          border-right: 0px #e0e0e0 solid;
          padding: 2px 0;
          height: 36px;
          vertical-align: middle;
          &:last-child {
            border-right: 0px #e0e0e0 solid;
          }
          span {
            display: none;
          }
          p {
            text-align: left;
            padding: 0px 10px;
            margin: 0;
            font-size: 12px;
            color: #000;
          }
        }
      }

      .row {
        display: table-row;
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px #e0e0e0 solid;
        &:last-child {
          border-bottom: 0px #e0e0e0 solid;
        }
        &:hover {
          background-color: #edf4f9;
          cursor: pointer;
        }
        &:active {
          background-color: #edf4f9;
          cursor: pointer;
        }
        .Cell {
          display: table-cell;
          border: none;
          vertical-align: middle;
          border-bottom: 1px #e0e0e0 solid;
          border-right: 0px #e0e0e0 solid;
          padding: 2px 0;
          height: 50px;
          vertical-align: middle;
          span {
            display: none;
          }
          font-size: 15px;
          p {
            text-align: left;
            padding: 0px 10px;
            font-size: 14px;
          }
          &.w1 {
            width: 200px;
          }
          &.w2 {
            width: 300px;
          }
          &.w3 {
            width: 150px;
          }
          .end {
            width: 180px;
          }
          .buttonStyle {
            width: 85px;
            padding: 0;
          }
        }
      }
    }
    .button-group {
      width: 206px;
      float: right;
    }
    .tabItem {
      border-bottom: 1px solid #d8d8d8;
      margin-top: 45px;

      a {
        display: inline-block;
        color: #6c7077;
        margin-right: 15px;
        font-size: 14px;
        border-bottom: 2px solid transparent;
        padding: 0 10px 12px;
        cursor: pointer;
        &.active {
          color: #1b394e;
          border-color: #000;
        }
      }
    }
  }
  /*==============================================*/
  &.user-search {
    background-color: #fff;
    .displayuser {
      .head {
        p {
          display: block;
          width: 100%;
          &.textResult {
            font-weight: normal;
            font-size: 16px;
            margin-bottom: 0;
            color: #1b394e;
            &.No {
              display: none;
            }
            span {
              font-weight: bold;
            }
          }
        }
        input {
          float: left;
          width: 220px;
        }
      }
    }
    .searchItem {
      &:after {
        clear: both;
        content: "";
        display: block;
      }
      .buttonStyle {
        padding: 0px 15px;
        margin: 0 10px;
      }
    }
  }
  /*==============================================*/
  &.user-detail {
    background-color: #f3f3f3;
    .showUser {
      background-color: #f3f3f3;
    }
    .displayuser {
      width: 680px;
      margin: 0 auto;
    }
  }
}

.verify {
  .contentVerify {
    padding-top: 20vh;
    color: #1b394e;
    .icon {
      background: url("../images/ic_account_circle.png") no-repeat center center;
      background-size: 50px auto;
      width: 50px;
      height: 50px;
      margin: 15px auto;
    }
    p {
      text-align: center;
    }
    p.text01 {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      margin: 10px 15px;
    }
    p.text02 {
      font-size: 16px;
      text-align: center;
      margin: 5px 15px;
    }
    a {
      color: #000;
    }
  }
  header .light-show {
    display: none;
  }
}

/*Signup Style*/
.signup-page {
  padding: 10% 0 20%;
  .inner {
    margin: 0 auto;
    width: 300px;
  }
  input[type="text"] {
    width: 100%;
    border: 1px solid transparent;
    font-size: 14px;
    border-radius: 5px;
    height: 40px;
    &.error {
      border-color: red !important;
    }
  }
  h2,
  h3 {
    font-weight: 400;
    color: #fff;
    margin: 0;
  }
  h2 {
    font-size: 17px;
    border-bottom: 1px solid #3b7093;
    padding-bottom: 12px;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 15px;
    padding-bottom: 15px;
    text-align: center;
  }
  .row {
    margin-bottom: 10px;
  }
  .button-style {
    color: #1b394e;
    background: #92d2df;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    border: none;
    width: 100%;
    outline: none;
    &:hover {
      background: #92d2df;
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.4;
    }
  }
}

.pending-screen {
  padding-top: 64px;
  .verify-block {
    text-align: center;
    margin: 15% auto;
    img {
      width: 64px;
      display: block;
      margin: 0 auto 12px;
    }
    b {
      font-size: 20px;
      font-weight: 700;
    }
    p,
    b {
      color: #1b394e;
    }
    p {
      font-size: 16px;
      margin-top: 0;
      color: #6c7077;
    }
    .buttonStyle {
      color: #1b394a;
      &:hover {
        color: #fff;
      }
    }
  }
  button.call-link {
    background-color: transparent;
    color: #1b394e;
    font-size: 16px;
    border: 1px solid #1b394e;
    border-radius: 5px;
    padding: 12px 105px;
    display: table;
    margin: 15px auto;
    i {
      background: url("../images/call-icon.png") no-repeat;
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 7px;
    }
  }
}

.breadcrumb {
  padding-top: 25px;
  padding-bottom: 15px;
  color: #b0b5be;
  font-size: 14px;
  a {
    color: #1b394e;
  }
  span {
    color: #1b394e;
  }
}

.activity-screen {
  .date-group {
    h3 {
      font-size: 16px;
      color: #1b394e;
      margin-top: 30px;
      margin-bottom: 18px;
    }
    .item-change {
      border: 1px solid #e0e0e0;
      background: #fff;
      padding: 4px 20px;
    }
    .icon {
      border-radius: 50%;
      background-color: #1b394e;
      width: 32px;
      height: 32px;
      text-align: center;
      color: #fff;
      line-height: 32px;
      font-size: 12px;
      margin-top: 7px;
      float: left;
      text-transform: uppercase;
    }
    .log {
      float: left;
      margin: 0 15px;
      width: 70%;
    }
    .middle {
      display: table-cell;
      vertical-align: middle;
      height: 48px;
      font-size: 14px;
      color: #4a4a4a;
    }
    .astro-list {
      margin: 0 0 0 40px;
      padding-left: 30px;
      padding-bottom: 3px;
    }
    .time {
      float: right;
      .middle {
        font-size: 12px;
        color: #b0b5be;
      }
    }
  }
}

.flights-page {
  padding-top: 85px;
  width: 645px;
  margin: 0 auto;
  min-height: 600px;
  .flight-detail {
    .breadcrumb {
      span {
        font-weight: 700;
      }
    }
    .hero-image {
      background: url("../images/hero-image.png") no-repeat;
      background-size: cover;
      width: 100%;
      height: 148px;
      position: relative;
      .flight-name {
        position: absolute;
        bottom: 20px;
        left: 20px;
        color: #fff;
        z-index: 2;
        b {
          font-weight: bold;
          font-size: 24px;
          display: block;
          padding-bottom: 5px;
        }
      }
      &:after {
        content: "";
        background: url(../images/rec-shadow.png) no-repeat bottom left;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
    .flight-list {
      .trip-item {
        padding: 20px;
        .bottom {
          .col {
            &.col-1 {
              width: 100%;
            }
          }
        }
      }
    }
    .depart-detail {
      padding-bottom: 0;
      .gray-line {
        width: 100%;
        display: block;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 15px;
      }
      .item {
        padding-bottom: 20px;
        &:after {
          content: "";
          clear: both;
          display: block;
        }
        .info,
        .icon {
          float: left;
        }
        .icon {
          width: 28px;
          height: 30px;
        }
        .info {
          width: 80%;
          h4 {
            margin: 0;
            font-size: 12px;
            color: #757575;
            padding-top: 2px;
          }
          p {
            font-size: 16px;
            color: #000;
            font-weight: bold;
            margin: 0;
            padding: 3px 0;
          }
          span {
            font-size: 14px;
            color: #757575;
          }
        }

        &.depart {
          .icon {
            background: url(../images/depart-icon.png) no-repeat left top;
          }
        }
        &.passenger {
          padding-left: 0;
          padding-bottom: 12px;
          .icon {
            background: url(../images/ic_account_child.png) no-repeat left 4px;
          }
        }
      }
    }
    .air-detail {
      h3 {
        margin: 0;
        font-size: 16px;
        color: #000;
      }
      .aircode {
        font-size: 12px;
        color: #757575;
        padding-bottom: 10px;
      }
      ul {
        margin: 0;
        padding: 0;
        &.air-gallery {
          li {
            display: inline-block;
            margin-right: 3px;
          }
        }
      }
      .passenger-section {
        padding-top: 20px;
        .item-passenger {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #d8d8d8;
          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            margin-bottom: 0;
          }
          &:after {
            content: "";
            clear: both;
            display: block;
          }
          .avatar {
            width: 40px;
            padding-right: 10px;
          }
          .avatar,
          .info-passenger {
            float: left;
          }
          .info-passenger {
            width: 80%;
            b {
              font-size: 16px;
              color: #000;
            }
            p {
              margin: 0;
              font-size: 12;
              color: #757575;
            }
          }
        }
      }
    }
  }
  .pass-flights {
    margin-bottom: 25px;
    a {
      border-radius: 5px;
      text-align: center;
      display: block;
      float: none;
      width: 100%;
      border: 1px solid #979797;
      margin: 0;
      padding: 15px 0;
      height: auto;
      line-height: inherit;
      text-transform: none;
      font-size: 14px;
      color: #1b394e;
      &:hover {
        background: #1b394e;
        color: #fff;
      }
    }
  }
  .in-progress {
    position: relative;
    margin-top: 20px;
    .progressLine {
      width: 80%;
      position: relative;

      &:after,
      &:before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #d8d8d8;
        top: -2px;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: -25px;
      }
      .gray-line {
        height: 2px;
        background: #dcdcdc;
        width: 115%;
      }
      i {
        background: url("../images/ic_local_airport@3x.png") no-repeat;
        position: absolute;
        width: 15px;
        width: 25px;
        background-size: contain;
        height: 25px;
        top: -11px;
        z-index: 2;
      }
    }
  }
  .flight-list {
    .no-flight {
      text-align: center;
      padding: 55px 0;
      margin: 0;
      background: url(../images/ic_local_airport_no_flight.png) no-repeat top
        center;
      margin-top: 100px;
      margin-bottom: 100px;
    }
    .loading {
      bottom: 0;
      top: auto;
      width: 100%;
      position: fixed;
      left: 0;
      text-align: center;
      margin-left: 0;
      height: 100px;
    }
    .today {
      font-size: 20px;
      color: #000;
      padding-top: 20px;
      padding-bottom: 15px;
      display: block;
    }
    .trip-item {
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      padding: 25px;
      margin-bottom: 8px;
      box-shadow: 1px 3px 8px #d8d8d8;
      cursor: pointer;
      &:last-child {
        margin-bottom: 20px;
      }
      &:after,
      .top:after {
        content: "";
        clear: both;
        display: block;
      }
      .col {
        float: left;
      }
      .top {
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .col {
          width: 33%;
          &.ic_flight {
            width: 33%;
            img {
              margin-left: 15px;
            }
          }
        }
      }
      .bottom {
        .col {
          width: 35%;
          &.col-1 {
            width: 65%;
          }
          & > div {
            padding-bottom: 5px;
          }
        }
      }
      .on-time {
        background: url("../images/fc_ontime.png") no-repeat left 1px;
        padding-left: 22px;
        color: #757575;
        font-size: 14px;
        span {
          color: #4caf50;
        }
      }
      .trip-number {
        background: url("../images/fc_confirmed.png") no-repeat left 1px;
        padding-left: 24px;
        color: #757575;
        span {
          color: #000;
        }
      }
      .wifi {
        background: url("../images/fc_wifi.png") no-repeat left 1px;
        padding-left: 24px;
      }
      .air {
        background: url("../images/fc_tail.png") no-repeat left 1px;
        padding-left: 24px;
        font-size: 14px;
        color: #757575;
        span {
          color: #000;
        }
      }
      .passenger {
        background: url("../images/passengers.png") no-repeat left 0;
        padding-left: 24px;
        color: #000;
        font-size: 14px;
      }
      .pending {
        background: url("../images/fc_pending.png") no-repeat left 0;
        padding-left: 24px;
        color: #757575;
        font-size: 14px;
        span {
          color: #d0021b;
        }
      }
      .call-air {
        border-top: 1px solid #d8d8d8;
        margin-left: -25px;
        margin-right: -25px;
        margin-top: 15px;
        a {
          color: #1b394e;
          font-size: 14px;
          background: url("../images/fc_phone.png") no-repeat left 0;
          padding-left: 24px;
          display: inline-block;
          margin-left: 20px;
          margin-top: 15px;
        }
      }
      h3 {
        color: #757575;
        font-size: 14px;
        margin: 0;
        padding-bottom: 2px;
      }
      .city {
        font-size: 20px;
        color: #000;
        padding-bottom: 3px;
      }
      .time {
        color: #757575;
        font-size: 18px;
      }
    }
  }
}
