@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700");

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  height: auto;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  overflow-x: hidden;
}
body {
  margin: 0;
}
body.userPage,
body.verify {
  background: #fff;
}
body.userPage .loading,
body.userSearchPage .loading,
body.userDetail .loading {
  display: block;
}
a {
  text-decoration: none;
}
.app-container {
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    height: 120%;
  }
}
:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.clear {
  clear: both;
}

a:link,
a:visited {
  color: #4a4a4a;
}

.loadingStyle {
  background-image: url("../images/three-dots.svg");
  background-repeat: no-repeat;
  background-position: center;
}

/* Select style */
select.select-style {
  /* styling */
  background: #1b394e;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 50px 10px 15px;
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%),
    radial-gradient(transparent 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 7px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: none;
}
/*===============Login Page===============*/
.line {
  clear: both;
  border-bottom: 1px #fff solid;
  width: 82%;
  position: relative;
  margin: 30px auto 20px;
  p {
    background: #1b394e;
    position: absolute;
    left: 50%;
    width: 50px;
    margin-left: -25px;
    top: -24px;
    text-align: center;
    span {
      text-align: center;
      color: #fff;
    }
  }
}
/*===============header===============*/
header {
  color: #fff;
  clear: both;

  a:link {
    color: white;
  }
  a:visited {
    color: white;
  }
  a:hover {
    color: white;
  }
  img {
    float: left;
    width: 170px;
    padding: 6px;
    margin-top: 6px;
    padding-left: 20px;
  }

  .menu {
    display: none;
  }
  .innerMenu {
    padding: 0 20px;
  }
  .popup_menu {
    display: none;
  }
  .show-desktop {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    height: 64px;
    text-align: center;
    color: #fff;
    background: #003764;
    width: 100%;
    z-index: 101;
    .innerHeader {
      // width: 1024px;
      // margin: 0 auto;
    }
  }
  .light-show {
    float: left;
    padding-left: 30px;
    ul.list-item {
      padding: 0;
      margin: 0;
      li {
        float: left;
        list-style: none;
        a {
          display: block;
          cursor: pointer;
          height: 62px;
          display: block;
          margin: 0px 15px;
          padding: 0px 10px 0 35px;
          span {
            padding-top: 23px;
            display: block;
          }
          &.active {
            border-bottom: 2px #fff solid;
            height: 62px;
            font-weight: bold;
          }

          &.flight {
            background: url("../images/ic_local_airport.png") no-repeat 10px
              24px;
          }
          &.admin {
            background: url("../images/ic_local_admin.png") no-repeat 10px 24px;
          }
          &.user {
            background: url("../images/ic_people.png") no-repeat 10px 24px;
            padding: 0px 10px 0 40px;
          }
          &.media {
            background: url("../images/ic_perm_media.png") no-repeat 10px 24px;
            padding: 0px 10px 0 42px;
          }
        }
      }
    }
  }
  .admin-show {
    float: right;
    height: 100%;
    position: relative;
    background: url("../images/arrow_bottom.png") no-repeat right center;
    cursor: pointer;
    display: block;
    &.active {
      background: url("../images/arrow_top.png") no-repeat right center;
    }
    .text {
      float: left;
      padding: 23px 30px 0 0;
    }
    .img-user {
      float: left;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fff;
      margin: 19px 18px;
      overflow: hidden;
      img {
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }
    .sub-menu {
      position: absolute;
      right: -20px;
      top: 0;
      background: #1b394e;
      width: 105%;
      height: 44px;
      top: 64px;
      border-top: 1px #4d6575 solid;
      display: none;
      button {
        background-color: transparent;
        border: none;
        width: 100%;
        color: #fff;
        display: block;
        line-height: 44px;
      }
    }
  }
  .contactShow {
    float: right;
    position: relative;
    display: block;
    margin: 23px 30px 0 20px;
    padding-bottom: 0px;
    button {
      color: #7edfeb;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      background: url("../images/ic_aircare.svg") no-repeat left center;
      padding-left: 17px;
      cursor: pointer;
      &.borderBottom {
        &:after {
          content: "";
          border-bottom: 1px solid #7edfeb;
          opacity: 0;
          width: 100%;
          top: -11px;
          position: relative;
        }
      }
    }
    .displayContact {
      margin-top: 10px;
      position: absolute;
      right: -25px;
      top: 30px;
      &.active {
        display: block;
      }
    }
  }
  .showBottom {
    display: table;
    background-color: #fff;
    width: 200px;
    border-radius: 2px;
    .item {
      text-align: left;
      margin: 20px 13px;
    }
    a {
      color: #000;
      font-size: 14px;
      line-height: 16px;
      &.phone {
        background: url("../images/fc_phone.png") no-repeat left center;
        padding-left: 30px;
      }
      &.email {
        background: url("../images/fc_email.png") no-repeat left center;
        padding-left: 30px;
      }
    }
  }
  .arrowUp {
    width: 12px;
    height: 0;
    margin: -14px 0 0 114px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 5px solid #fff;
  }
  .displayContact {
    display: none;
  }
}

footer {
  border-top: 1px #31678c solid;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  color: #fff;
  background: #1b394e;
  a {
    color: #92d2df;
  }
}
/*===============Login===============*/
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  margin-left: -30px;
  margin-top: -30px;
  display: none;
  z-index: 99999;
  img {
    width: 60px;
  }
}
/*===============Profile===============*/
.block-profile {
  clear: both;
  padding-top: 25px;
  .title {
    font-size: 16px;
    color: #1b394e;
    display: inline-block;
    padding-bottom: 15px;
  }
  &.astro-detail {
    .content-block {
      .astro-title {
        .col:first-child {
          padding-top: 18px;
          padding-bottom: 18px;
        }
      }
    }
  }
  .content-block {
    display: table;
    background: #fff;
    width: 100%;
    border-bottom: 1px #e0e0e0 solid;
    .row {
      display: table-row;
      width: 100%;
      overflow: hidden;
      &.control {
        &.active {
          background-color: #fff;
          .edit {
            display: none;
          }
          .save {
            display: block;
          }
          input.inputtext {
            pointer-events: auto;
            cursor: auto;
          }
          .controlMesag {
            border: 1px #1b394e solid;
            .controlscroll {
              display: block;
            }
          }
        }
        .edit {
          opacity: 0;
        }

        &:hover {
          background-color: #edf4f9;
          input.inputtext {
            background-color: #edf4f9;
          }
          .edit {
            opacity: 1;
          }
        }
        .unittype {
          height: 20px;
        }
        .email-verfied {
          padding-left: 10px;
          color: #757575;
          font-size: 14px;
          display: block;
          margin-top: 5px;
        }
      }
      .buttonStyle.usersearch {
        float: right;
        padding: 0 10px;
        &:hover {
          color: #fff;
        }
      }
      .col {
        display: table-cell;
        border-top: 1px #e0e0e0 solid;
        padding: 0 15px;
        font-size: 14px;
        vertical-align: top;
        &.middle,
        &:last-child {
          vertical-align: middle;
        }
        &.c02,
        &.email-column {
          vertical-align: top;
          padding-top: 12px;
          padding-bottom: 12px;
        }
        label {
          display: table;
          padding-top: 18px;
        }
        &.c02 {
          padding-top: 11px;
        }
        span {
          padding-right: 15px;
        }
        .userid {
          display: block;
          clear: both;
          border-bottom: 1px #e0e0e0 solid;
          border-top: 0;
          overflow: hidden;

          &.active {
            .control {
              .deleteControl {
                display: block;
              }
              .editControl {
                display: none;
              }
            }
          }
          &:hover {
            background-color: #edf4f9;
            .editControl {
              opacity: 1;
            }
          }
          &:last-child {
            border-bottom: 0;
          }
          .text {
            float: left;
            margin-top: 5px;
            line-height: 47px;
            padding-left: 10px;
          }
          .control {
            float: right;
            .deleteControl {
              clear: both;
              display: none;
            }
            .editControl {
              clear: both;
            }
          }
        }
        &.idUser {
          width: auto;
          padding-right: 0px;
        }
        &.add {
          color: #1b394e;
          cursor: pointer;
          padding-top: 18px;
          padding-bottom: 18px;
        }
        a {
          color: #1b394e;
        }
        &:first-child {
          font-weight: bold;
          width: 200px;
        }
        input.inputtext {
          width: 100%;
          height: 28px;
        }
        select {
          width: 80px;
          padding: 5px 0;
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 15px;
        }
        input.inputtext,
        select,
        span.Email {
          padding-left: 10px;
          font-size: 14px;
          outline: none;
          border: none;
          color: #4a4a4a;
          &:disabled {
            background: transparent;
            color: #4a4a4a;
            opacity: 1;
          }
        }
      }
    }
  }
}
.controlMesag {
  border: 0;
  border-radius: 5px;
  overflow: hidden;
  height: 32px;
  width: 68px;
  position: relative;
  .contentText {
    height: 60px;
    position: absolute;
    left: 0;
    top: 0;
    span {
      height: 32px;
      line-height: 32px;
      padding: 0px 10px;
      display: block;
    }
  }
  .controlscroll {
    position: absolute;
    right: 7px;
    top: 3px;
    display: none;
    .prev {
      width: 5px;
      height: 7px;
      background: url("../images/arrow_bottom1.png") no-repeat right center;
      position: absolute;
      right: 4px;
      top: 14px;
      cursor: pointer;
      &.disable {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .next {
      width: 5px;
      height: 7px;
      background: url("../images/arrow_top1.png") no-repeat right center;
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
      &.disable {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
.employee {
  display: none;
  .selected {
    height: 30px;
    padding: 0px 6px;
    font-size: 16px;
    outline: none;
    border: none;
    width: 68px;
    option {
      height: 30px;
      line-height: 30px;
      padding-top: 8px;
      span {
        padding-top: 10px;
      }
    }
  }
}
h2 {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}
/*=================Account page==================*/
body.accountPage {
  background: #fff;
  header .light-show {
    display: none;
  }
}
.container {
  width: 300px;
  margin: 108px auto 0;
}
.inputstyle {
  border: 1px #f4f4f2 solid;
  outline: none;
  height: 45px;
  border-radius: 5px;
  width: 100%;
  margin: 5px 0;
  padding: 0 3%;
  font: normal 15px/45px Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.inputstyle.error {
  border: 1px #bd0000 solid;
}
.textAreaStyle {
  border: 1px #f4f4f2 solid;
  outline: none;
  border-radius: 5px;
  padding: 2% 3%;
  width: 100%;
  margin: 5px 0 10px;
  font: normal 15px/1.3 Arial, "Helvetica Neue", Helvetica, sans-serif;
}
#accountPage header img {
}
#accountPage .buttonStyle {
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin: 0;
  margin-top: 10px;
}

.loadingPage {
  top: 0 !important;
  width: 100%;
  text-align: center;
}
.ui.dimmer {
  background-color: rgba(23, 55, 76, 0.85);
}
.signUpModal.forgotModal {
  .boxPadding {
    margin: 25px;
    padding: 0;
    position: relative;
  }
  p {
    line-height: 1;
    margin-bottom: 0;
  }
  .backToLogin {
    &.btnClose {
      background-color: transparent !important;
      border: none;
      top: 3px;
      position: absolute;
      left: 0;
      cursor: pointer;
    }
  }
  .content {
    border-top: 1px solid #d5d5d5;
    padding-top: 20px !important;
    margin-left: -25px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: -25px;
  }
}

.content {
  margin-top: 20px;
}

.firebase-ui {
  background-color: #17374c;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 25vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    &.inner {
      top: 40vh;
    }

    > img {
      margin: auto;
    }

    > div {
      margin-top: 2.5vh;
    }
  }
}

.working-shade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.33;
  z-index: 1;
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    margin-left: -30px;
    margin-top: -30px;
    img {
      width: 60px;
    }
  }
}

.button-wrapper {
  cursor: pointer;
  outline: none;
  padding: 0;
  border: none !important;
  background-color: transparent !important;
}
