/*==================Responsive===================*/
@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1052px;
  }
  .flightCards.newWidth {
    .ui.container {
      width: 1227px;
      &.fluid {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .bookATrip {
    .chooseSection {
      padding-left: 24px;
      padding-right: 16px;
    }
    .chooseAccounts {
      margin-left: 16px;
    }
  }
  header .show-desktop .innerHeader {
    width: 100%;
  }
  .flightScreen .flexColumn {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .login-page {
    .text.top {
      margin-top: 50px;
    }
    .signupBox {
      .title {
        padding-top: 111px;
      }
    }
  }
  .firebaseui-idp-google {
    span {
      margin-top: 0;
    }
  }
  .ui.tiny.modal {
    &.forgotModal {
      margin: 0 0 0 -47.5%;
    }
  }
  .hideFlightList {
    display: none !important;
  }
  .flightScreen {
    .navMenu {
      overflow-y: hidden;
      height: auto;
      min-height: auto;
      position: absolute;
      width: 100%;
    }
    .flightDetailHidden {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .cardDetail {
      position: relative;
      z-index: 2;
      height: 100%;
    }
    .flexColumn {
      width: calc(100% - 1rem);
    }
    .cardDetail {
      .hideOnMobile {
        display: none;
      }
    }
    .flightDetailHidden {
    }
  }
  .bookATrip {
    .chooseAirPort {
      .ui.input {
        input {
          -webkit-appearance: none;
        }
        input[type="date"] {
          padding-left: 32px !important;
        }
      }
    }
    .tripRequest {
      margin-bottom: 10px;
    }
    .chooseAccounts {
      margin-left: 0;
      margin-bottom: 16px !important;
      .ui.selection.dropdown {
        width: 100%;
      }
    }
    .chooseSection {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
      border: none;
      padding-bottom: 14px;
      & > .ui.grid {
        width: auto;
        border-top: 1px solid #d8d8d8;
      }
    }
    .ui.grid {
      .row.column {
        .col {
          width: 100% !important;
        }
      }
    }
    .noPaddingLR {
      .column {
        padding-right: 1rem !important;
      }
      &.paddingMobile {
        .column {
          padding-right: 8px !important;
          padding-left: 8px !important;
        }
      }
    }
    .btnSubmit {
      text-align: center;
      margin-left: -1rem;
      margin-right: -1rem;
      .ui.button {
        width: 48%;
        margin-right: 0;
        &.primary {
          margin-left: 2%;
        }
      }
    }
    .ui.container {
      &.customMargin {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    .noPaddingR {
      &.col {
        padding-right: 1rem !important;
      }
    }
    .chooseAirPort {
      .input {
        margin-bottom: 8px;
      }
    }
    .addLeg {
      margin-left: 0;
      margin-bottom: 20px;
    }
    /*ADD PASSENGERS*/
    .passengerSection {
      .ui.grid {
        .row.chooseAirPort {
          .column {
            width: 100% !important;
          }
        }
      }
      .ui.container {
        .passengerWidth {
          width: 100%;
          .addLegPass {
            margin-left: 0;
          }
        }
      }
      .removePass {
        top: 0;
      }
      .noMarginTop {
        margin-top: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
  .ui.container {
    &.fluid {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .displayuser .content .row .col.w1 {
    width: 150px;
  }
  .displayuser .content .row .col.w2 {
    width: 180px;
  }
  .displayuser .content .row .col.w3 {
    width: 120px;
  }
  .displayuser .content .row .col.end {
  }
  .userDetail .displayuser {
    width: 90%;
  }
  #loginPage {
    margin: 35px auto 100px;
  }
  #accountPage .innerDetail {
    height: 100%;
    background: #fff;
    padding-bottom: 100px;
  }
  header .light-show ul.list-item li a {
    margin: 0px 0px;
  }
  header {
    .light-show {
      padding-left: 18px;
    }
  }
  .loadingPage {
    left: -30% !important;
    img {
      width: 600px;
    }
  }
}

@media screen and (max-width: 767px) {
  .tripRequest {
    .tripSummary {
      .legRow {
        .ui.grid {
          .row.column {
            .col {
              width: 100% !important;
              &.date {
                padding-left: 0 !important;
              }
              &.time {
                padding-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  .bookATrip {
    .ui.grid {
      .row.column {
        .col {
          &.date {
            padding-right: 14px !important;
          }
          &.time {
            padding-right: 10px !important;
          }
        }
      }
    }
    .tripSummary {
      .chooseSection {
        padding-bottom: 0;
      }
      .clsTransportation {
        .transportCol {
          margin-left: -20px;
          .legTitle {
            margin-left: -9px;
          }
          .removeLeg {
            margin-right: 0;
          }
        }
        .borderBottom {
          margin: 14px -12px 10px -22px !important;
        }
        .customMarginTransport {
          margin-left: -23px;
        }
      }
      .borderBottom {
        margin-left: -1rem;
        margin-right: -1rem;
        .showMore,
        .showLess {
          margin-top: 14px !important;
        }
        .borderBottom {
          margin: -31px 25px -1px 35px;
          &.legRow {
            .ui.grid {
              .noPaddingLR {
                padding-bottom: 0;
                &.paddingMobile {
                  margin-bottom: 16px;
                  margin-top: -5px;
                }
              }
              .clsTitleL {
                margin-left: -9px;
              }
            }
          }
          &.mobile {
            margin: 0;
            .borderTransportation {
              margin-left: 0;
              margin-right: 6px;
            }
            .clsTransportation {
              .transportCol {
                display: flex;
                margin-left: -13px;
                margin-right: -37px;
              }
            }
            .removeLeg {
              margin-right: 6px;
            }
            .noTop {
              margin-left: -7px;
            }
            .clsTitleL {
              margin-left: -7px;
            }
            textarea {
              width: 98%;
            }
          }
          &.mobileBottom {
            margin: 0;
            .clsTransportation {
              padding-right: 2px;
              padding-left: 35px;
            }
            .btnSubmit {
              margin-right: 0;
            }
            .borderTransportation {
              margin-left: 0;
              margin-right: 5px;
            }
            .noTop {
              margin-left: -7px;
            }
          }
        }
        &.passengerInfo {
          width: auto;
          margin-top: 14px;
        }
        .paddingLeft {
          padding-left: 1rem !important;
          padding-right: 1rem !important;
          &.passengerSubmit {
            padding: 0;
            .column {
              width: 100% !important;
              padding-bottom: 16px;
            }
            .addLegPass {
              margin-top: 0 !important;
            }
            .btnSubmit {
              padding-right: 30px;
            }
          }
          &.top {
            margin-top: 8px;
          }
          .dropdowntop {
            margin-right: 0 !important;
          }
          .column {
            padding-right: 0;
          }
        }
        .btnSubmit {
          margin-left: 0;
          margin-right: 0;
          .column {
            padding-right: 0;
          }
        }
        .marginLeft {
          margin-top: 0;
          .widthCatery {
            margin-top: 0 !important;
            .editTrip {
              &.ground {
                margin-left: 16px;
              }
            }
            .borderTransportation {
              margin-top: -10px;
            }
          }
        }
      }
      .paddingLeft {
        padding-left: 0 !important;
        &.bottom {
          margin-bottom: 10px;
        }
        &.right {
          .column {
            width: 100% !important;
          }
          .passengerList {
            &.bottom {
              margin-bottom: 5px !important;
            }
          }
        }
        .addLegPass {
          margin-top: 8px !important;
          margin-left: 0 !important;
        }
      }
      .column {
        padding-left: 0 !important;
      }
      .noPaddingTop {
        &.top {
          padding-top: 24px;
        }
      }
      .sub {
        padding-bottom: 9px;
      }
      .widthCatery {
        padding-right: 0;
        .passengerList {
          margin-top: 8px;
          &.bottom {
            margin-bottom: 8px;
          }
        }
        .paddingLeft {
          &.right {
            .passengerList {
              margin-bottom: 8px;
            }
            .column {
              margin-bottom: 8px !important;
            }
          }
        }
      }
      .borderTransportation {
        margin: 10px 1rem 1px 1rem;
        &.top {
          margin-top: -13px;
        }
      }
      .parentTextArea {
        margin-right: 0;
        .column {
          padding-right: 0;
        }
      }
      .passengerSection {
        .editSection {
          padding: 0;
          .legTitle {
            padding-left: 0;
          }
          .passengerWidth {
            margin: 0;
            padding-left: 22px;
            padding-right: 14px;
          }
        }
      }
      .passengerSection {
        .noMarginTop {
          margin-bottom: 30px;
        }
      }
      .noPaddingR {
        &.col {
          padding-right: 0 !important;
        }
      }
    }
    .chooseAirPort {
      &.last {
        padding-bottom: 0;
      }
      .tenCol {
        margin-top: 8px;
      }
      .noPaddingLR {
        &.padding {
          padding-bottom: 5px;
        }
        &.last {
          padding-bottom: 2px;
        }
        &.paddingMobile {
          padding-top: 0;
          .column {
            padding-right: 9px !important;
            padding-left: 8px !important;
          }
        }
        &.departureDate {
          margin-left: -2px !important;
          .column {
            margin-bottom: 8px;
          }
        }
      }
      .clsTitleL {
        margin-left: -5px;
      }
    }
    .chooseSection {
      .transportCol {
        width: auto;
        span {
          margin-left: -5px;
        }
      }
      .borderBottom {
        margin-right: 0;
      }
      .removeLeg {
        margin-right: 15px;
      }
    }
    .clsBottom {
      padding-bottom: 0 !important;
    }
    .confirmBox {
      width: auto;
      p {
        margin: 0 24px;
      }
    }
  }
  a.showMore,
  a.showLess {
    margin-left: 16px !important;
  }
  span {
    margin-top: 8px;
  }
  .noTop {
    margin-top: 0;
  }
  .ui.selection.dropdown {
    margin-bottom: 8px;
    .text {
      margin-top: -7px !important;
    }
  }
  .requestWidth {
    .borderBottom {
      margin: 0 -14px !important;
      padding: 16px 0;
      .paddingLeft {
        padding: 8px 6px !important;
        &.rowBottom {
          margin-top: 16px !important;
        }
        .editTrip {
          padding-left: 16px !important;
        }
        &.right {
          padding-bottom: 0;
          .passengerList {
            padding-bottom: 14px !important;
          }
          .column {
            width: 100% !important;
            margin-bottom: 0 !important;
          }
        }
        &.button {
          .column {
            width: 100% !important;
            margin-left: 0px;
            margin-top: 20px;
          }
        }
      }
      .sub {
        padding: 5px 0 0 0 !important;
      }
      .parentTextArea {
        margin-right: 0 !important;
      }
      .dropdowntop {
        margin-right: 0 !important;
      }
      .btnSubmit {
        margin-left: 2px;
        .ui.button {
          width: 49% !important;
        }
      }
      .removePass {
        margin-right: 0 !important;
      }
    }
    .chooseAirPort {
      .noPaddingLR {
        margin: 0 6px !important;
      }
    }
    .borderPassenger {
      margin: -39px 20px 10px 20px !important;
    }
    .borderBottomForm {
      margin: 0 -8px !important;
      .chooseAirPort {
        .column {
          padding-right: 0;
        }
        .noPaddingLR {
          margin: 0 0 !important;
        }
      }
      .removePass {
        margin-right: 0 !important;
      }
    }
  }
  .contactShow {
    margin-right: 13px !important;
    .displayContact {
      right: -6px !important;
      .arrowUp {
        margin: -14px 20px 0 133px;
      }
    }
  }
  .flightScreen {
    .flexColumn {
      order: 1;
      margin-left: 1rem;
      margin-top: 1rem;
    }
    .navMenu {
      .ui.secondary.pointing.menu {
        margin-top: -1rem;
      }
    }
  }
  .cardDetail {
    .passengerCard .itineraryDesc .header {
      padding-left: 20px;
    }
    .itineraryCard.airCard .avatarGrid .itineraryDesc .header {
      padding-left: 20px;
    }
    .cardBanner {
      .editControl {
        .gridRow {
          padding: 17px 0 0;
          .column:nth-child(2) {
            padding-left: 0;
          }
          a {
            font-size: 12px;
            padding-left: 20px;
            &.iItinerary {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .noFlightSelected {
    padding-bottom: 50%;
  }
  .reset-popup {
    .content {
      margin-top: 80px;
    }
  }
  .group_button {
    top: 60px;
    right: 0;
    left: 0;
    display: none;
  }
  .group_button .buttonStyle {
    margin: 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }
  header img {
    float: none;
    margin: 0 auto;
  }
  .menu {
    display: block;
    position: absolute;
    top: 14px;
    margin-bottom: 16px !important;
  }
  .menu.none {
    display: none;
  }
  .menu.active {
    display: block;
  }
  .menu img {
    width: 24px;
    height: 20px;
  }
  .group_button.active {
    display: block;
  }
  .head p {
    text-align: center;
    float: none;
  }
  .page-user .displayuser .content .Heading {
    display: none;
  }
  .page-user .displayuser .content .row .Cell {
    display: block;
    width: 100%;
    overflow: hidden;
    border: none;
    height: auto;
    p {
      margin: 0;
      padding: 0px 14px;
      &.name {
        font-weight: bold;
      }
    }
    &.end {
      width: 100%;
      float: left;
      .button-group {
        width: auto;
        padding-left: 8px;
        float: left;
      }
    }
  }

  .page-user {
    .displayuser {
      .content {
        display: inline-block;
        .row {
          display: inline-block;
          padding: 14px 0 10px;
          &:last-child {
            border-bottom: 1px #e0e0e0 solid;
          }
        }
      }
      .head {
        p {
          float: none;
          display: inline-block;
          width: 100%;
          text-align: left;
          margin: 0;
          margin-top: 20px;
          margin-bottom: 5px;
        }
        input {
          width: 89%;
          margin: 0;
          float: none;
          display: block;
        }
      }
    }
    &.user-detail {
      .displayuser {
        width: 94%;
        .block-profile {
          .content-block {
            .row {
              &.control {
                border-top: 1px #e0e0e0 solid;
                display: block;
                &:hover {
                  .col {
                    &:last-child {
                      display: block;
                    }
                  }
                }
                &.email {
                  &:hover {
                    .col {
                      &:last-child {
                        display: none;
                      }
                    }
                  }
                }
                .email-verfied {
                  padding-bottom: 5px;
                }
              }
              .col {
                padding-left: 0;
                float: left;
                border: none;
                height: auto;
                width: 55%;
                &.email-column {
                  .inputtext {
                    display: block;
                    height: auto;
                  }
                }
                select {
                  height: auto;
                  margin-top: 12px;
                }
                &:first-child {
                  padding-left: 15px;
                  width: 25%;
                  &.add,
                  &.aircare {
                    line-height: 1.2;
                    height: auto;
                  }
                }
                &:last-child {
                  padding-left: 10px;
                  float: left;
                  width: 100%;
                  display: none;
                  &.idUser {
                    display: table-cell;
                    padding-bottom: 0;
                    padding-left: 0;
                    .text {
                      padding-left: 15px;
                    }
                    .control {
                      .col {
                        padding-bottom: 0;
                      }
                      .save {
                        margin-top: 5px;
                        margin-right: 5px;
                      }
                    }
                  }
                  .edit {
                    float: left;
                  }
                  .save {
                    float: left;
                  }
                }
                &.aircare {
                  label {
                    padding-bottom: 18px;
                  }
                }
              }
            }
          }
          &.astro-detail {
            .content-block {
              .row {
                .col {
                  &:first-child {
                    margin: 18px 0;
                    line-height: 1.2;
                    padding-bottom: 0;
                    padding-top: 0;
                  }
                  .userid {
                    .editdata {
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #loginPage {
    margin: 15px auto 0px;
  }
  header {
    .top {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0%;
      overflow: hidden;
    }
    .innerMenu {
      position: absolute;
      top: 64px;
      background-color: #1b394e;
      height: 0%;
      width: 100%;
      padding: 0;
      overflow: hidden;
    }
    .menu {
      display: block;
      position: absolute;
      left: 0;
      top: 0px;
      width: 50px;
      height: 64px;
      right: inherit;
      background: url(../images/ic_menu.png) no-repeat 15px center;
      z-index: 1000;
      &.active {
        background: url(../images/ic_close.png) no-repeat 15px center;
        width: 50px;
        height: 64px;
      }
    }
    img {
      width: 170px;
      position: relative;
      left: 30px;
      top: 4px;
    }
    .admin-show {
      float: none;
      display: block;
      width: 100%;
      min-height: 65px;
      clear: both;
      border-top: 1px #4d6575 solid;
      background: url(../images/arrow_bottom.png) no-repeat right 25px top 27px;
      &.active {
        background: url(../images/arrow_top.png) no-repeat right 25px top 27px;
      }
      .sub-menu {
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        height: 64px;
        clear: both;
        background-color: #2f536c;
        button {
          color: #fff;
          display: block;
          line-height: 65px;
          padding-left: 59px;
        }
      }
    }
    .light-show {
      width: 100%;
      float: none;
      padding-left: 0;
      clear: both;
    }
    .light-show ul.list-item li {
      width: 100%;
      float: none;
      border-top: 1px #4d6575 solid;
      a {
        margin-left: 0;
        padding-left: 48px;
        &.flight,
        &.admin {
          padding-left: 58px;
          background-position: 20px center;
        }
        &.user {
          padding-left: 60px;
          background: url(../images/ic_people.png) no-repeat 20px center;
        }
        &.media {
          padding-left: 60px;
          background: url(../images/ic_perm_media.png) no-repeat 20px center;
        }
        &.active {
          &.flight,
          &.admin {
            padding-left: 56px;
            background-position: 18px center;
          }
          &.user {
            padding-left: 58px;
            background: url(../images/ic_people.png) no-repeat 18px center;
          }
          &.media {
            padding-left: 58px;
            background: url(../images/ic_perm_media.png) no-repeat 18px center;
          }
        }
      }
    }
    .light-show ul.list-item li a.active {
      border-bottom: none;
      border-left: 2px #fff solid;
    }
    .light-show ul.list-item li a span {
      text-align: left;
    }
    .show-desktop {
      z-index: 1000;
    }
    .show-desktop .top {
      display: none;
    }

    .popup_menu {
      position: absolute;
      left: 0;
      display: none;
      top: 0;
      width: 100%;
      height: 100%;
      background: #1b394e;
      z-index: 999;
      .top {
        position: relative;
        min-height: 100%;
        height: auto;
        overflow: auto;
      }
      .innerMenu {
        height: auto;
        top: 64px;
        left: 0;
      }
    }
  }
  .userSearchPage .page-user .displayuser .head p {
    margin: 18px 0;
  }
  .userSearchPage .searchItem {
    display: inline-block;
  }
  .userSearchPage .page-user .displayuser .head input {
    width: 200px;
  }
  .userDetail .displayuser {
    width: 90%;
  }
  .buttonStyle.deactivate.red {
    float: left;
    margin-left: 10px;
  }
  .block-profile .content-block .row .col:first-child {
    width: 100px;
  }
  .block-profile .content-block .row .col:nth-child(3) {
  }
  .showMobile {
    display: block;
  }
  .showDestop {
    display: none;
  }
  .page-user {
    &.user-search {
      .displayuser {
        .head {
          input {
            width: 65%;
          }
        }
      }
    }
  }
  .block-profile {
    .content-block {
      .row {
        .col {
          padding-left: 10px;
          &:first-child {
            width: 85px;
            padding-left: 10px;
          }
        }
      }
    }
  }
  .flights-page {
    width: 97%;
    .flight-list {
      .trip-item {
        padding: 15px;
        .call-air {
          margin-left: -15px;
          margin-right: -15px;
        }
      }
    }
  }
  .activity-screen {
    .date-group {
      .item-change {
        padding: 10px 20px 0 20px;
      }
      .log {
        width: 62%;
      }
      .time {
        float: left;
        margin: 10px 0 10px 46px;
        .middle {
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  body {
    min-width: 320px;
  }

  &.user-detail {
    .displayuser {
      .block-profile {
        .content-block {
          .row {
            .col {
              &.email-column {
                span.Email {
                  word-break: break-word;
                  width: 165px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}
