.popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  &.callaircare-popup {
    .inner {
      width: 400px;
      margin-left: -200px;
      .content {
        padding-top: 5px;
      }
      .footer {
        .buttonStyle {
          height: 32px;
        }
      }
    }
  }
  &.reset-popup {
    .content {
      padding: 0px 20px 5px;
      p {
        font-size: 16px;
        color: #000;
      }
    }
  }

  .bg_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  p.text {
    text-align: center;
    color: #000;
    font-size: 16px;
  }
  .inner {
    width: 600px;
    position: absolute;
    left: 50%;
    margin-left: -300px;
    top: 10%;
    background: #fff;
    overflow: hidden;
    border: 1px #f4f4f2 solid;
  }
  .header {
    clear: both;
    border-bottom: 1px #bebaba solid;
    padding: 14px;
    span {
      text-align: left;
      font-size: 18px;
    }
    strong {
      font-weight: bold;
    }
  }
  .content {
    clear: both;
    padding: 20px 10px 5px;
    .inputSearch {
      position: relative;
      width: 500px;
      overflow: hidden;
      margin-top: 10px;
      margin: 0 auto;
      .btnSerach {
        background-color: #1b394e;
        float: left;
        height: 46px;
        width: 115px;
        line-height: 46px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: none;
        &.disable {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
    .text {
      span {
        font-weight: bold;
      }
    }

    .checkbox {
      padding-top: 20px;
      label {
        padding-left: 10px;
      }
    }
    .showAcount {
      border-top: 1px #e0e0e0 solid;
      margin: 25px 0 0;
      display: block;
      &.boder {
        border-top: 0px #e0e0e0 solid;
      }
      .inner-show {
        padding: 0 6%;
      }
      &.error {
        display: none;
      }
      .errorText {
        width: 100%;
        display: block;
        position: relative;
        padding: 3px 17px;
        &:before {
          width: 5px;
          height: 5px;
          background: #000;
          content: "";
          border-radius: 50%;
          display: block;
          position: absolute;
          left: 0;
          top: 10px;
        }
      }
      .listAcount {
        clear: both;
        .notResulf {
          display: block;
          padding: 20px 0;
          font-size: 16px;
          color: #000;
          text-align: center;
        }
        .item {
          clear: both;
          border-bottom: 1px #e0e0e0 solid;
          padding: 15px 0;
          position: relative;
          &:last-child {
            border-bottom: 0px #e0e0e0 solid;
          }
          .userID {
            clear: both;
            font-weight: 700;
            color: #000;
            font-size: 16px;
          }
          .Account {
            clear: both;
          }
          .listItem {
            clear: both;
            padding-top: 10px;
            position: relative;
            .name {
              color: #000;
            }
            .role {
              color: #757575;
            }
          }
          .error {
            clear: both;
            padding: 10px 0;
          }
          .remove {
            position: absolute;
            right: 0;
            top: 20px;
            color: #1b394e;
            padding-left: 20px;
            font-size: 12px;
            cursor: pointer;
            background: url("../images/remove.png") no-repeat 0 1px;
          }
        }
        span {
          width: 100%;
          display: block;
          position: relative;
          padding: 3px 17px;
        }
      }
    }
  }
  .footer {
    clear: both;
    overflow: hidden;
    padding: 10px 0;
    width: 100%;
    bottom: 2px;
    border-top: 1px #e0e0e0 solid;
    .buttonStyle {
      float: right;
    }
  }
  .supPopup {
    position: absolute;
    width: 400px;
    height: 150px;
    background: #fff;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -75px;
    border: 1px #ccc solid;
    display: none;
    .text {
      text-align: center;
      padding: 14px 0;
    }
    .g_button {
      clear: both;
      display: inline-block;
      width: 100%;
      text-align: center;
      .buttonStyle {
        display: inline-block;
        float: none;
        margin: 0px 20px;
      }
    }
  }
  &.feedback-popup {
    .working-shade {
      z-index: 101;
    }
    .inner {
      width: 497px;
      border-radius: 4px;
      background-color: #f4f5f6;
      z-index: 100;
      position: fixed;
    }
    .content {
      padding: 0;
      margin-top: 0;
      h2.title {
        color: #000000;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        padding: 20px 16px;
        text-align: left;
        border-radius: 4px 4px 0 0;
        background-color: #ffffff;
        box-shadow: inset 0 -1px 0 0 rgba(217, 217, 217, 0.5);
      }
      .feedbackBox {
        text-align: center;
        padding-top: 9px;
        h4 {
          color: #212121;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          text-align: center;
          margin-bottom: 15px;
        }
        .flightCity {
          span {
            display: inline-block;
            color: #757575;
            font-size: 14px;
            line-height: 16px;
          }
          .flight-icon {
            background: url("../images/airplane.svg") no-repeat left center;
            background-size: contain;
            width: 15px;
            display: inline-block;
            height: 15px;
            margin: 0px 13px;
            position: relative;
            top: 3px;
          }
        }
      }
      .thumbBox {
        .md-radio {
          display: inline-block;
          margin-right: 44px;
          margin-bottom: 23px;
          &:last-child {
            margin-right: 0;
          }
        }
        .thumbIcon {
          width: 90px;
          height: 90px;
          padding-top: 0;
          label {
            height: 100%;
            &:before,
            &:after {
              width: 90px;
              height: 90px;
            }
            &:before {
              background: url("../images/thumbsup.svg") no-repeat left center;
            }
          }
          &.checked {
            label {
              &:after {
                background: url("../images/thumbsup_selected.svg") no-repeat
                  left center;
              }
            }
          }
          &.thumbdown {
            label {
              &:before {
                background: url("../images/thumbsdown.svg") no-repeat left
                  center;
              }
            }
            &.checked {
              label {
                &:after {
                  background: url("../images/thumbsdown_selected.svg") no-repeat
                    left center;
                }
              }
            }
          }
        }
      }
    }
    .additionalBox {
      padding: 0 24px 16px;
      p {
        color: #757575;
        font-size: 13px;
        font-weight: bold;
        line-height: 15px;
        padding-bottom: 9px;
        margin-bottom: 0;
      }
      textarea {
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        background-color: #ffffff;
        height: 94px;
        width: 100%;
        color: rgba(0, 0, 0, 0.47);
        padding: 18px 18px 13px;
        font-size: 16px;
        outline: none;
        line-height: 19px;
        resize: none;
      }
    }
    .footer {
      border-radius: 4px 4px 0 0;
      background-color: #ffffff;
      box-shadow: inset 0 -1px 0 0 rgba(217, 217, 217, 0.5);
      text-align: right;
      padding: 16px 0 15px;
      .button {
        border: 2px solid #1b394e;
        border-radius: 16px;
        width: 120px;
        height: 32px;
        line-height: 16px;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        margin-right: 16px;
        background: #fff;
        &:hover {
          background-color: #1b394e;
          color: #fff;
        }
        &.submit-button {
          &.disabled {
            border: 2px solid #1b394e;
            opacity: 0.4;
            border-radius: 16px;
            background-color: #1b394e;
            color: #fff;
          }
        }
      }
    }
  }
}
/*responsive================*/
@media screen and (max-width: 640px) {
  .popup {
    .inner {
      width: 100% !important;
      margin-left: 0 !important;
      left: 0;
      top: 0;
      position: relative;
      min-height: inherit;
      border: 0;
      overflow-x: hidden;
    }
    .content {
      padding-top: 70px !important;
      padding-bottom: 20px;
      .inputSearch {
        width: 100%;
      }
    }
    .styleInput {
      &.w1 {
        width: 54%;
      }
    }
    .footer {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      .buttonStyle {
        display: inline-block;
        float: none;
      }
      .supPopup {
        margin-left: -181px;
        width: 360px;
      }
    }
  }
}
