body,
a,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto" !important;
}
body {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
.noMarginBottom {
  margin-bottom: 0 !important;
}
.flex {
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
}
@keyframes shimmerEffect {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.flightScreen {
  background-color: #f3f3f3;
  height: 100%;
  margin-top: 0;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;

  .addLeg {
    display: inline-block;
    color: #1b394e;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background: url(../images/add_leg.png) no-repeat left center;
    padding-left: 29px;
    background-size: 20px;
    padding: 5px 0 0 29px;
    margin: 0 0 27px 36px;
  }
  .addLegPass {
    display: inline-block;
    margin-top: 25px;
    color: #1b394e;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background: url(../images/add_leg.png) no-repeat left center;
    padding-left: 29px;
    background-size: 20px;
    padding: 5px 0 0 29px;
  }
  .shimmerEffect {
    &:before {
      content: "";
      top: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      -webkit-animation: slide 1s infinite 3s;
      animation: shimmerEffect 1s infinite 3s;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
    }
  }
  .leftShimmer {
    background: url(../images/leftShimmer.png) no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .rightShimmer {
    background: url(../images/rightShimmer.png) no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .paddingTop {
    padding-top: 0;
    margin-top: 0;
  }
  .flightCards {
    margin-top: 0;
    .moreLoader {
      position: relative;
      top: 0;
      margin: 20px 0 40px;
    }
  }
  .noPadding {
    padding: 0 !important;
  }
  .noPaddingTop {
    margin-top: 0;
    &.customMargin {
      border-bottom: 1px solid #d8d8d8;
    }
  }
  .noPaddingRight {
    padding-right: 0 !important;
  }
  .noPaddingLeft {
    padding-left: 0 !important;
  }
  .flex {
    min-height: inherit;
  }
  .flightHeader {
    position: relative;
    width: 100%;
    margin-top: 0;
    top: 0;
    min-height: 114px;
    background-color: #003764;
    box-shadow: 0 0.5px 0 0 #b2b2b2;
    padding: 38px 0 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .ui.button {
      border-radius: 16px;
      background-color: #7edfeb;
      color: #1b394e;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      font-family: "Roboto";
      padding: 10px 17px;
      &:hover {
        background-color: #316284;
      }
      &:active {
        background-color: #162d3d;
      }
    }
    .ui.container {
      margin-top: 0;
    }
    .dropdown {
      .text {
        color: #ffffff !important;
        font-size: 32px !important;
        line-height: 38px;
      }
      .dropdown.icon {
        width: 5px;
        height: 9px;
        color: #fff;
        margin-left: 14px;
        position: relative;
        top: -3px;
      }
      .menu {
        width: 100%;
        .item {
          .text {
            font-size: 16px !important;
            color: #1b394e !important;
            line-height: 16px;
          }
        }
      }
    }
  }
  .displayFlex {
    display: flex !important;
    flex-direction: row !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: 100%;
  }
  .flexColumn {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 37%;
    order: -1;
  }
  .navMenu {
    position: relative;
    margin-bottom: 0 !important;
    overflow-x: hidden;
    .ui.list.flightCards {
      .no-flight {
        margin-top: -5px !important;
        height: 100%;
        .contentLoader {
          position: absolute;
          top: 25px;
          width: 100%;
          text-align: center;
          border: none !important;
        }
        .flightIcon {
          margin-top: 55px;
          color: #1b394e;
          font-family: Roboto;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          img {
            display: table;
            margin: 0 auto 22px;
            width: 48px;
          }
        }
      }
    }
    .ui.secondary.pointing.menu {
      flex: 1 100%;
      border-color: #d8d8d8;
      margin-bottom: 0;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
      border-bottom-width: 1px;
      position: relative;
      z-index: 4;
      min-height: 63px;
      a.item {
        color: #9b9b9b;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        padding: 26px 33px 20px 32px;
        display: inline-block;
        width: 50%;
        margin: -2px auto;
        &.active {
          color: #1b394e;
          border-color: #1b394e;
        }
      }
    }
  }

  .ui.segment {
    border-radius: 0;
    border: none;
    background-color: #ffffff;
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0;
    margin-right: 0;
  }
}
.ui.tiny.modal.forgotModal {
  margin-top: auto !important;
  display: inline-block !important;
  top: 120px;
  position: absolute;
  margin-left: -270px;
}
/*Flight Cards*/
.flightCards {
  margin-top: 0;
  &.list {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 63px);
    height: calc(100% - 63px);
  }
  .loadingNow {
    min-height: 100px;
    width: 100%;
    margin-top: -4px !important;
    background: transparent;
    border: none;
    box-shadow: none;
    .ui.loader {
      top: 15%;
    }
  }
  .loading {
    top: 50%;
    width: 100%;
    position: absolute;
    left: 0;
    text-align: center;
    margin-left: 0;
    height: 100px;
    margin-top: -30px;
    img {
      width: 24px;
    }
  }
  .no-flight {
    width: 100%;
    margin-top: -1rem !important;
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      padding: 20px 0;
    }
  }
  .trip-item {
    margin-bottom: 8px;
    .cardParent {
      margin-top: 29px;
    }
    .account-card {
      color: #757575;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      background: url("../images/account.svg") no-repeat left center;
      padding-left: 20px;
      margin-top: 21px;
      margin-bottom: 3px;
    }
    .card-item {
      .card-ui {
        margin-top: 1rem;
        .column {
          margin-bottom: 0 !important;
        }
      }
      .divider {
        border: none;
        border-top: 1px solid #d8d8d8;
        position: relative;
        z-index: 100;
        margin: 13px 0;
      }
      .bottom {
        box-shadow: inset 0 -1px 0 0 #d9d9d9;
        .column {
          margin-bottom: 0;
        }
      }
      .wrapper {
        cursor: pointer;
        &:hover {
          .gridRow {
            background-color: #f5f5f5;
            &:before {
              box-shadow: none;
            }
          }
        }
        &.active {
          pointer-events: none;
        }
        &:active,
        &.active {
          .gridRow {
            background-color: #e8e8e8;
            &:before {
              box-shadow: none;
            }
          }
        }
      }
    }
    h3 {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .top {
      padding-bottom: 12px;
      padding-top: 0;
      margin-top: 13px;
      .column {
        margin-bottom: 0;
      }
      h4 {
        color: #757575;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 0;
        margin-top: 13px;
        font-weight: 400;
      }
      .city {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
      }
      .time {
        color: #757575;
        font-size: 12px;
        line-height: 14px;
      }
      .progressLine {
        margin-top: 25px;
      }
      .ic_flight {
        text-align: center;
      }
      .ic_flight.in-progress {
        position: relative;
        margin-top: 14px;
        .progressLine {
          position: relative;
          margin-right: 15px;
          .progressActive {
            position: absolute;
            height: 1px;
            left: 0;
            top: 0;
            z-index: 10;
            background: #000;
          }
          &:after,
          &:before {
            content: "";
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #d8d8d8;
            top: -2px;
          }
          &:before {
            left: -2px;
            background: #000000;
          }
          &:after {
            right: -15px;
          }
          &.completed {
            &:after {
              background: #000000;
            }
          }
          .gray-line {
            height: 2px;
            background: #d8d8d8;
            width: calc(100% + 15px);
          }
          i {
            position: absolute;
            width: 15px;
            background-size: contain;
            height: 25px;
            top: -6px;
            z-index: 2;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .bottom {
      .bRow {
        padding-bottom: 8px;
      }
      span {
        padding-left: 20px;
        color: #000;
        font-size: 14px;
        line-height: 16px;
      }
      .trip-row {
        color: #757575;
      }
      .trip-number {
        background: url("../images/confirmed.svg") no-repeat left 1px;
        color: #757575;
      }
      .on-time,
      .arrived-at {
        background: url("../images/ontime.svg") no-repeat left 0;
        color: #757575;
        span {
          color: #4caf50;
        }
      }
      .arrived-at {
        background: url("../images/arrived.svg") no-repeat left 0;
        span {
          color: #000;
        }
      }
      .air {
        background: url("../images/tail.svg") no-repeat left 0;
        b {
          color: #000;
          padding-left: 24px;
        }
      }
    }
  }
  .cardHeader {
    margin-top: 64px;
    background-color: #1b394e;
    box-shadow: 0 0.5px 0 0 #b2b2b2;
    .container {
      margin-top: 0;
    }
    h3 {
      color: #ffffff;
      font-size: 32px;
      font-weight: 300;
      line-height: 38px;
      padding: 38px 0;
    }
  }
  .cardContent {
    margin-top: 26px;
    .cardTitle {
      color: #757575;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }
    .flightCardList {
      .card-item {
        .trip-number {
          background-position: left 2px;
        }
      }
    }
    .card {
      width: 100%;
    }
  }
  .grid-feeback {
    box-shadow: inset 0 -1px 0 0 #d9d9d9;
    .row {
      padding: 0;
      padding-top: 1rem;
    }
  }
  .give-feedback {
    color: #1b394e;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    width: 100%;
    display: block;
    background: transparent;
    border-radius: 0;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
    i {
      background: url("../images/modify.svg") no-repeat left center;
      width: 16px;
      height: 12px;
      display: inline-block;
      margin-right: 11px;
    }
  }
  .ui.segment {
    border-radius: 0;
    border: none;
    background-color: #ffffff;
  }
  .ui.card.confirmedFeedback {
    margin-bottom: 0;
    width: 100%;
    border: 1px solid rgba(23, 50, 70, 0.4);
    background-color: rgba(83, 176, 240, 0.1);
    border-radius: 0;
    z-index: 0;
    .header {
      color: #173246;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
    .description {
      color: #173246;
      font-size: 14px;
      line-height: 16px;
      padding-right: 25px;
    }
    .closeConfirmBox {
      position: absolute;
      z-index: 100;
      background: url("../images/ic_close_confirmed.png") no-repeat center;
      width: 14px;
      height: 14px;
      background-size: contain;
      right: 16px;
      top: 14px;
      cursor: pointer;
    }
  }
}

.md-radio {
  margin: 16px 0;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: #eee;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: auto;
    position: relative;
    padding: 10px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #000;
    }
    &:after {
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      transform: scale(0);
      background: removeEditedAstroId;
    }
  }
}
.noFlightSelected {
  color: #757575;
  font-size: 25px;
  text-align: center;
  padding-top: 50%;
}
.cardDetail {
  border-left: 1px solid #d8d8d8;
  padding-bottom: 18px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0 !important;
  display: flex !important;
  flex-direction: column;
  height: auto;
  padding-right: 0 !important;
  &.hiddenLoading {
    overflow: hidden;
  }
  .ui.card {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  }
  .cardBanner {
    .closeBanner {
      background: url("../images/close_overlay@3x.png") no-repeat;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
    }
    .linearBg {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        transparent 100%
      );
      position: absolute;
      left: 0;
      min-height: 110px;
      bottom: 0;
      z-index: 1;
      width: 100%;
    }
    &.ui.card {
      width: 100%;
      border-radius: 0;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
      .cardBannerContent {
        position: relative;
        padding: 0;
      }
      .cardDescription {
        position: absolute;
        bottom: 25px;
        left: 24px;
        z-index: 2;
        .header {
          color: #ffffff;
          font-size: 32px;
          font-weight: 500;
          line-height: 38px;
          font-family: "Roboto";
          margin-bottom: 5px;
        }
        .meta {
          .date {
            color: #ffffff;
            font-family: "Roboto";
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
    .cardInformation {
      &.trip-item {
        padding-top: 0;
        padding-bottom: 18px;
        .account-card {
          margin-left: 5px;
        }
        .card-item {
          a {
            pointer-events: none;
            &:hover,
            &:active {
              .gridRow {
                background-color: transparent;
                &:before {
                  box-shadow: none;
                }
              }
            }
            .hours {
              color: #757575;
              font-size: 12px;
              line-height: 14px;
            }
          }
          .card-ui {
            margin-top: 0;
          }
        }
        .gridRow {
          .column {
            margin-bottom: 1rem !important;
            &.noPaddingLeft:last-child {
              padding-left: 2rem !important;
            }
          }
          &.bottom {
            box-shadow: none;
          }
        }
      }
      .gridRow {
        padding: 24px 8px 5px;
        &.top {
          h4 {
            margin-top: 0;
          }
          .ic_flight {
            text-align: center;
          }
          .progressLine {
            margin-top: 0;
            img {
              width: 19px;
            }
            span.hours {
              color: #757575;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
        &.bottom {
          padding-top: 17px;
        }
      }
      .divider.ui {
        width: calc(100% - 16px);
        margin: 1rem auto;
      }
    }
    .editControl {
      .gridRow {
        padding: 17px 0 0;

        button {
          color: #1b394e;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          padding-left: 27px;
          &.iModify {
            background: url("../images/modify.svg") no-repeat left center;
            background-size: 16px;
          }
          &.iCalendar {
            background: url("../images/calendar.svg") no-repeat left center;
          }
          &.iItinerary {
            background: url("../images/itinerary.svg") no-repeat left center;
            margin-left: 35px;
          }
          &.disabled {
            opacity: 0.37;
            pointer-events: none;
          }
        }
      }
    }
  }
  .trackFlight {
    a {
      color: #1b394e;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      background: url("../images/ic_trackflight.png") no-repeat left center;
      padding-left: 29px;
    }
  }
  .itineraryCard {
    .title {
      color: #757575;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-bottom: 11px;
      padding-left: 8px;
    }
    .iCon {
      width: 14px;
      display: inline-block;
      height: 14px;
      position: relative;
      top: 2px;
      &.car {
        background: url("../images/ic_car.png") no-repeat;
      }
      &.depart {
        background: url("../images/ic_depart.png") no-repeat;
      }
      &.assort {
        background: url("../images/ic_assort.png") no-repeat;
      }
    }
    .itineraryDesc {
      margin-bottom: 9px;

      .header {
        color: #757575;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }
      .meta {
        .desc {
          color: #000000;
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          display: block;
          padding-right: 70px;
          a {
            &:after {
              display: none;
            }
          }
        }
        .carName {
          color: #757575;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    a,
    div[role="button"] {
      display: block;
      cursor: pointer;
      position: relative;
      .accordionTitle {
        margin-top: 16px;
      }
      &:after {
        content: "";
        background: url("../images/ic_arrow_right.png") no-repeat right center;
        width: 8px;
        height: 13px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -4px;
      }
      &.open {
        .accordionTitle {
          margin-bottom: 16px;
          background: #e6e6e6;
        }
        &:after {
          background: url("../images/ic_arrow_down.png") no-repeat right center;
          width: 13px;
          height: 8px;
        }
      }
      .gridRow {
        padding-left: 8px;
      }

      &.borderBottom {
        .gridRow {
          padding-bottom: 0;
          box-shadow: inset 0 -1px 0 0 #d8d8d8;
        }
        .itineraryDesc {
          margin-bottom: 9px;
        }
        margin-bottom: 25px;
      }
    }
    &.airCard {
      padding-top: 8px;
      .gridRow {
        padding-bottom: 0;
      }
      h4 {
        color: #000000;
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        margin-bottom: 8px;
        span {
          color: #000000;
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
        }
      }
      .gallery {
        padding-left: 8px;
        margin-top: 0;
        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 2px;
          margin-bottom: 2px;
          width: 80px;
          img {
            width: 100%;
          }
        }
      }
      .avatarGrid {
        .itineraryDesc {
          .header {
            color: #030303;
            font-size: 16px;
            line-height: 19px;
            span {
              display: block;
              color: #9b9b9b;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
        .circularForAvatar {
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50%;
          background-size: cover !important;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .accordionTitle {
      .gridRow {
        padding-bottom: 0;
      }
    }
    .accordionContent {
      padding-left: 36px;
      .gridRow {
        padding-bottom: 0;
      }
      .header {
        color: #757575;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
      .meta {
        .desc,
        a {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
        }
        a,
        .link {
          color: #1b394e;
          text-decoration: underline;
          &:after,
          a:after {
            background: transparent !important;
          }
        }
      }
    }
  }
  .passengerCard {
    .gridRow {
      padding-left: 8px;
      padding-bottom: 0;
    }
    .nameText {
      width: 40px;
      height: 40px;
      background-color: #1b394e;
      color: #ffffff;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
    }
    .itineraryDesc {
      .header {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
      }
    }
    .leadText {
      color: #757575;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
    }
  }
  .itineraryCard {
    .cateringSection {
      .accordionContent {
        .vendorTitle {
          font-size: 16px;
          color: #000;
        }
      }
    }
  }
}

.cardDetailLoader {
  margin-right: 10px;
  .flightCardsLoader {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    margin-bottom: 10px;
    .cardBanner {
      border-radius: 0 2px 0 0;
      background-color: rgba(199, 199, 205, 0.5);
    }
    .cardInformation {
      background-color: #ffffff;
      position: relative;
      &:before {
        content: "";
        background: url(../images/airplane.svg) no-repeat center 27%;
        width: 32px;
        height: 32px;
        background-size: 32px;
        position: absolute;
        top: 14%;
        left: 50%;
        margin-left: -16px;
        opacity: 0.4;
      }
    }
  }
  .itineraryCardLoader,
  .passengerCardLoader {
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    margin-bottom: 10px;
  }
  .airCardLoader,
  .cardParentLoader {
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    margin-bottom: 20px;
  }
}
.cardParentLoader {
  position: relative;
  &:before {
    content: "";
    background: url(../images/airplane.svg) no-repeat center 27%;
    width: 25px;
    height: 26px;
    background-size: 25px;
    position: absolute;
    top: 25%;
    left: 50%;
    margin-left: -12px;
    opacity: 0.4;
  }
}

/*BOOK A TRIP*/
.flightScreen {
  .hasScroll {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 186px);
    min-height: calc(100vh - 186px);
  }
}
.bookATrip {
  /*STYLE FOR SELECT*/
  .ui.selection.dropdown {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #ffffff;
    height: 40px;
    .menu {
      .item {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0;
        padding: 15px 0 11px 24px !important;
        border-color: #d8d8d8;
      }
    }
    .spanOther {
      padding-left: 0;
    }
    .text {
      color: #000000;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: -2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      &.default {
        opacity: 0.37;
      }
      img {
        display: inline;
        margin-right: 10px;
        top: 2px;
        height: 32px;
        width: 32px;
      }
      .spanImage {
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    &.active {
      &:hover {
        border-color: #d9d9d9;
      }
      .menu {
        z-index: 100;
        border: 1px solid #1b394e !important;
        border-radius: 0 0 2px 2px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        .spanOther {
          padding-left: 46px;
          padding-bottom: 13px;
        }
      }
    }
  }
  .ui.disabled.dropdown {
    z-index: 1;
  }
  .fromAirport {
    z-index: 1;
  }
  .subBroder {
    width: 100%;
    margin: 4px -6px 0 14px;
    border-bottom: 1px solid #d8d8d8;
    &.transportation {
      margin: 4px 0 0 0;
    }
  }
  .clmLegTitle {
    margin-top: 16px !important;
  }
  h4 {
    color: #ffffff;
    font-family: Roboto;
    font-size: 32px;
    line-height: 38px;
    font-weight: 400;
  }
  .tripRequest {
    margin-top: 57px;
    margin-bottom: 57px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    .ui.grid {
      .column {
        &.passengerCol {
          width: 30% !important;
        }
        &.airCraftCol {
          width: 70% !important;
        }
      }
    }
  }
  .tripTitle {
    color: #1b394e;
    font-family: Roboto;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding-top: 32px;
  }
  .chooseAccounts {
    margin-left: 36px;
    margin-bottom: 24px !important;
    .ui.selection.dropdown {
      width: 434px;
    }
  }
  .subTitle {
    color: #757575;
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 24px;
  }
  .chooseSection {
    border-top: 1px solid #d8d8d8;
    padding: 24px 36px 20px 36px;
    .legTitle {
      color: #757575;
      font-family: Roboto;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }
    .borderBottom {
      border-bottom: 1px solid #d8d8d8;
      margin: 20px 7px 5px 0px;
    }
    .customMarginTransport {
      &.marginTop {
        margin-top: 20px !important;
      }
    }
    .marginTop {
      margin-top: 30px !important;
      .addRow {
        padding-left: 26px;
        color: #1b394e;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        &.transportation {
          background: url("../images/ic_transportation_blue.svg") no-repeat left
            center;
        }
        &.catering {
          background: url("../images/ic_catering_blue.svg") no-repeat left
            center;
        }
      }
    }
    .transportCol {
      width: 100%;
      margin-top: 16px;
      span {
        margin-left: 0;
      }
    }
    .cateryCol {
      width: 100%;
      margin-top: 7px;
    }
  }
  .removeLeg {
    background: url("../images/remove_leg.png") no-repeat right center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
  }
  .removePass {
    background: url("../images/remove_leg.png") no-repeat right center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 10px;
    margin-right: 10px;
  }
  .addressTitle {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .passNumber {
    color: #1b394e;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  .chooseAirPort {
    margin-top: 7px;
    padding-top: 0 !important;
    margin-left: 6px;
    .clsTitle {
      margin-left: -6px;
    }
    .textarea {
      width: 100%;
      outline: none;
      color: #000000;
      font-family: Roboto;
      font-size: 16px;
      line-height: 19px;
      padding: 13px 12px;
    }
    .ui.input {
      width: 100%;
      input {
        padding-left: 9px;
        padding-right: 9px;
        &[type="time"] {
          padding-right: 0;
        }
      }
    }
  }
  span {
    color: #757575;
    font-family: Roboto;
    font-size: 13px;
    font-weight: bold;
    line-height: 15px;
    margin-bottom: 8px;
    display: block;
    img {
      display: inline;
      margin-top: -6px;
    }
  }
  .noPaddingLR {
    padding-left: 8px;
    .column {
      padding-right: 6px !important;
      padding-left: 8px !important;
    }
    &.departureDate {
      padding-right: 7px !important;
    }
  }
  .noPaddingR {
    padding-right: 0 !important;
  }
  .chooseSection {
    & > .ui.grid {
      width: 100%;
    }
    .ui.selection.dropdown {
      width: 100%;
      min-width: inherit;
      margin: 0;
      left: 0;
      top: 0;
      background-color: #fff !important;
      &.active {
        box-shadow: none;
        border-color: #96c8da;
      }
    }
  }
  input[type="text"]::-webkit-input-placeholder {
    /* Microsoft Edge */
    opacity: 0.37;
    color: #000000;
    font-family: Roboto;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
  }
  .btnSubmit {
    text-align: right;
    margin: 1px 24px 0px;
    .ui.button {
      border: 2px solid #1b394e;
      border-radius: 16px;
      background: transparent;
      color: #1b394e;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      width: 176px;
      height: 32px;
      padding: 0;
      line-height: 30px;
      text-align: center;
      &.disabled {
        opacity: 0.4;
      }
      &.primary {
        background-color: #1b394e;
        color: #fff;
        margin-left: 8px;
      }
    }
  }
}
.tripConfirmation {
  .confirmBox {
    width: 604px;
    min-height: 399px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    display: table;
    margin: 94px auto 0;
    padding-top: 73px;
    padding-bottom: 58px;
    h3 {
      color: #1b394e;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      padding-top: 70px;
      background: url(../images/ok-icon.png) no-repeat top center;
    }
    p {
      color: #6c7077;
      font-family: Roboto;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      width: 327px;
      display: table;
      margin: auto;
    }
  }
  .navLink {
    display: table;
    margin: 20px auto 0;
    a {
      height: 40px;
      width: 314px;
      border: 1px solid #1b394e;
      border-radius: 20px;
      color: #1b394e;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      font-weight: 400;
      padding: 12px 89px;
      display: table;
      margin: 0 auto 8px;
      &:hover {
        background: #1b394e;
        color: #fff;
      }
    }
  }
}

/*ADD PASSENGERS*/
.passengerSection {
  .borderTop {
    border: 1px solid #d8d8d8;
  }
  .btnSubmit {
    text-align: right;
    margin: 1px auto 0px;
    margin-top: 1px solid;
    border-top: 1px solid #d8d8d8;
  }
  .chooseAirPort {
    margin-top: 0;
  }
  .ui.container {
    .passengerWidth {
      width: 538px;
      margin: 16px auto;
      padding-bottom: 16px;
      border-bottom: 1px solid #d8d8d8;
      .addLegPass {
        margin-left: -1rem;
        margin-top: 0;
      }
      &.noMarginTop {
        margin-top: 0;
        margin-bottom: 6px;
        border: none;
      }
    }
  }
  .noMarginTop {
    margin-top: 0;
  }
}

/*ADD TRIP SUMMARY*/
.tripRequest {
  .autocomplete-dropdown-container {
    .autocomplete-box {
      border: 1px solid #1b394e !important;
      border-radius: 0 0 2px 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      .suggestion-item--active {
        background-color: rgba(0, 0, 0, 0.03) !important;
      }
    }
    position: absolute;
    z-index: 2;
    border: none !important;
    background-color: #ffffff;
    padding-right: 15px;
    width: 100%;
    &:before {
      border-top: 1px solid #1b394e !important;
    }
    .suggestion-item,
    .suggestion-item--active {
      margin-bottom: 0;
      border-color: #d8d8d8;
      span {
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        padding: 15px 15px 11px 24px !important;
        margin-bottom: 0;
      }
    }
  }
  &.legs {
    .borderTransportation {
      display: none;
    }
  }
  &.summary {
    &.ui.container {
      width: 658px;
    }
  }
  &.confirmation {
    background: transparent;
    box-shadow: none;
  }
  .tripSummary {
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    .parentTextArea {
      padding-top: 0;
    }
    .clsTransportation {
      padding-left: 2rem;
      padding-right: 3rem;
      .borderTransportation {
        margin: 2px 0 5px 22px;
        padding-top: 0;
      }
      .transportCol {
        margin-right: 0;
        .ui.selection.dropdown {
          width: 98%;
        }
      }
      .borderBottom {
        &.forSummary {
          display: none;
          &.row_1 {
            display: block;
            margin-left: 6px;
          }
        }
      }
      textarea {
        width: 99%;
      }
      .borderBottom {
        margin: 0;
      }
    }
    .borderBottom {
      border-bottom: 1px solid #d8d8d8;
      margin: 20px 0 0 0;
      &.legRow {
        border-bottom: 0;
        margin: 0;
      }
      .marginLeft {
        margin-left: 0;
        &.top {
          margin-top: -3rem;
        }
        .borderBottom {
          &.legRow {
            padding-left: 0;
            padding-right: 0;
          }
          .btnSubmit {
            margin-right: 38px;
          }
        }
        .clsTransportation {
          width: 100%;
          .chooseAirPort .tenCol {
            width: 100% !important;
          }
        }
      }
    }
    .borderTransportation {
      border-bottom: 1px solid #d8d8d8;
      margin: 2px 28px 2px 38px;
      &.top {
        margin-top: -12px;
      }
    }
    .legRow {
      .chooseSection {
        border-bottom: 0;
        padding-bottom: 16px;
        border-top: 0;
        .tenCol {
          width: 100% !important;
          padding-right: 0;
          margin-bottom: 16px;
        }
      }
      .transportCol {
        &.cateringCol {
          width: 100% !important;
        }
        .chooseAirPort {
          &.additional {
            .tenCol {
              width: 100% !important;
            }
          }
          .tenCol {
            width: 50% !important;
            padding-right: 0;
            padding-left: 16px;
            .noPaddingLR {
              padding-left: 6px;
              margin: 0 0 1rem;
            }

            .departureDate {
              .selection.dropdown {
                position: static;
              } 
              .eight {
                width: 45% !important;
                &:first-child {
                  width: 55% !important;
                }
              }
            }
          }
        }
      }
    }
    button {
      color: #1b394e;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      &.editTrip {
        background: url("../images/modify.svg") no-repeat left center;
        background-size: 16px;
        padding-left: 20px;
        margin-left: 16px;
        &.left {
          margin-left: 5px;
        }
        &.ground {
          margin-left: 30px;
        }
      }
      &.showButton {
        background: url("../images/ic_arrow_down.png") no-repeat right center;
        padding-right: 20px;
      }
    }
    .ui.container {
      .borderBottom {
        &.top {
          border-top: none;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
      .btnSubmit {
        .ui.button {
          width: 119px;
        }
      }
      .paddingLeft {
        padding-left: 2rem;
        &.title {
          padding-bottom: 0;
        }
        .addLegPass {
          margin-top: 16px;
          margin-left: 14px;
          &.noPaddingTop {
            margin-top: 0;
          }
        }
        .dropdowntop {
          margin-right: 2rem;
        }
        .removePass {
          margin-right: 0;
          top: 0;
        }
        &.rowBottom {
          margin-bottom: 14px;
          margin-top: 12px;
        }
        &.right {
          margin-right: 1rem;
          .column {
            margin-bottom: 17px;
          }
        }
        .dropdown {
          &.selection {
            width: 100%;
          }
        }
      }
    }
    .borderBottomForm {
      margin: 0 2rem;
      .selection.dropdown {
        width: 100%;
        min-width: inherit;
      }
      .removePass {
        margin-right: -15px;
        top: 0;
      }
      .chooseAirPort {
        margin-right: 1rem;
      }
    }
    .tripAccount {
      height: 19px;
      color: #1b394e;
      font-size: 16px;
      line-height: 19px;
    }
    .legTitle {
      color: #1b394e;
      font-size: 16px;
      line-height: 19px;
      padding-left: 7px;
      &.left {
        padding-left: 2rem;
      }
    }
    .flightTitle {
      color: #757575;
      font-size: 14px;
      font-weight: 500;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }
    .sub {
      width: auto;
      color: #212121;
      font-size: 14px;
      padding: 5px 0 14px 0;
      font-family: Roboto;
      font-size: 14px;
    }
    .subTitle {
      width: auto;
      color: #212121;
      font-size: 14px;
      margin-bottom: 0;
      word-wrap: break-word;
    }
    .airport {
      color: #757575;
      font-size: 12px;
      line-height: 17px;
    }
    .textarea {
      height: 64px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      background-color: #ffffff;
      width: 100%;
      padding: 9px;
    }
    .parentTextArea {
      margin-right: 2rem;
      margin-bottom: 8px !important;
    }
    .titleTop {
      color: #757575;
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 24px;
    }
    .borderPassenger {
      border-bottom: 1px solid #d8d8d8;
      margin: -29px 36px 10px 36px;
    }
    .passengerInfo {
      width: 100%;
      .sub {
        padding-bottom: 0;
      }

      .paddingLeft.right {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .passengerSection {
      .legTitle {
        font-size: 16px;
        padding-bottom: 24px;
      }
      .passNumber {
        font-size: 14px;
      }
      .editSection {
        width: 100%;
        padding-top: 16px;
        .chooseAirPort {
          padding-top: 16px;
        }
      }
      .noMarginTop {
        margin-top: 0;
      }
      .passengerWidth {
        width: auto !important;
        margin-left: 28px;
        margin-right: 26px;
        margin-top: 32px;
      }
      .firstRow {
        .passengerWidth {
          margin-top: 0;
        }
      }
      .btnSubmit {
        border-top: none !important;
        margin: 0;
        text-align: right;
        padding-right: 24px;
      }
      .passengerSubmit {
        padding-left: 16px !important;
        padding-right: 8px;
      }
    }
  }
  .widthCatery {
    width: 100%;
    margin-top: -2rem !important;
    .passengerList {
      &.bottom {
        margin-bottom: 8px;
      }
    }
  }
  .cateringWidth {
    width: 100%;
  }
  .marginTransportion {
    margin-left: 15px;
  }
  .widthRenderEditCatery {
    width: 100%;
    margin: 0 10px 0 15px;
  }
  .showMoreBtn {
    margin-top: 0 !important;
    .noPaddingTop {
      padding-top: 0;
    }
  }
  .showMore,
  .showLess {
    display: inline-block;
    color: #1b394e;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    background: url(../images/showMore.png) no-repeat right center;
    background-size: 16px;
    padding-right: 25px;
    margin-left: 2rem;
    margin-bottom: 24px;
  }
  .showLess {
    background: url(../images/showLess.png) no-repeat right center;
  }
}
